import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
import accountingListExport from './accountingLogList/accountingListExport'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.accounting',
      search: 'accounting.search_keyword',
      exportExcel: accountingListExport(this.vm),
      filter: {
        processed: {
          label: 'accounting.data.processed',
          type: 'selection',
          options: [false, true],
          optionTextPrefix: 'data',
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        amount: row.amount,
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => row.avatar,
      },
      create: {
        popupComponent: () => import('@/modules/accounting/views/accountingLogList/accountingListCreatePopup.vue'),
        disabledApply: (formData) => {
          if(window.helper.textEmpty(formData.name)) return true
          if(window.helper.textEmpty(formData.type)) return true
          if(window.helper.textEmpty(formData.amount)) return true
          if(formData.amount == 0) return true
          // @ts-ignore
          if(formData.type === this.vm.$accoutingConstants.TYPE_REVENUE) {
            if(formData.amount <= 0) return true
            if(!this.vm?.$helper.validator.onlyNumber(formData.amount)) return true
          }

          // @ts-ignore
          if(formData.type === this.vm.$accoutingConstants.TYPE_COST) {
            if(formData.amount <= 0) return true
            if(!this.vm?.$helper.validator.onlyNumber(formData.amount)) return true
          }
          return false
        },
        createApi: async (formData) => {
          return await this.vm?.$api.collection.accountingApi.create(formData)
        },
      },
      table: {
        data: <TableDataType>[
          {
            key: 'name',
            label: 'data.name',
            type: 'text',
          },
          {
            key: 'type',
            label: 'accounting.data.type',
            type: 'text',
            text: row => this.vm?.$t(`accounting.data.type.${row.type}`),
          },
          {
            key: 'amount',
            label: 'data.amount',
            type: 'text',
          },
          {
            key: 'processed',
            label: 'accounting.data.processed',
            type: 'text',
            help: 'accounting.data.processed.help',
            text: row => this.vm?.$t(`data.${row.processed}`),
            dataItemProperties: row => {
              if(row.processed != true) return { 'class': 'orange--text' }
              return { 'class': 'green--text' }
            },
          },
          {
            key: 'order_id',
            label: 'accounting.data.order_id',
            help: 'accounting.data.order_id.help',
            copyable: true,
            component: () => import('modules/accounting/views/accountingLogList/order.vue'),
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        setProcessedFinished: {
          targetLabel: row => row.name,
          label: 'accounting.action.set_processed_finished',
          column: 'processed',
          value: true,
        },
        setProcessedUnfinished: {
          targetLabel: row => row.name,
          label: 'accounting.action.set_processed_unfinished',
          column: 'processed',
          value: false,
        },
        delete: {
          targetLabel: row => row.name,
          confirmIdentity: true,
        },
      },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'accounting-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          removeTarget: row => row.name,
          confirmIdentity: true,
        },
      },
    }

  }
}

export default new listConfig()
