import {ExportExcelInterface} from "components/list/types/list";

export default (vm: Vue) => <ExportExcelInterface>{
  filename: (time: string) => `${vm?.$t('module.accounting')}`,
  sheets: [
    {
      label: 'module.accounting',
      prependAoa: (rawData: any[]) => {
        const totalAmount = window.eagleLodash.sumBy(rawData, 'amount')
        return [
          [vm.$t('accounting.data.total_amount')],
          [totalAmount],
          []
        ]
      },
      data: {
        created_at: { label: 'data.created_at', width: 18 },
        name: { label: 'data.name', width: 18 },
        type: {
          label: 'accounting.data.type',
          width: 12,
          value: row => vm?.$t(`accounting.data.type.${row.type}`),
        },
        amount: { label: 'data.amount', width: 12 },
        processed: {
          label: 'accounting.data.processed',
          width: 14,
          value: row => vm?.$t(`data.${row.processed}`),
        },
        order_id: { label: 'accounting.data.order_id', width: 20 },
        order_link: {
          label: 'accounting.data.order_link',
          width: 60,
          value: (row) => {
            const orderId = row.order_id
            if(!orderId) return null
            const url = vm.$router.resolve({
              name: 'order-update',
              params: { target: orderId },
            }).href
            const domain = window.location.origin
            return `${domain}${url}`
          },
        },
      },
    },
  ]
}
